// html {
//   scroll-behavior: smooth;
// }
// For Ecosense and Permablok
.description {
  > .container-images {
    position: relative;

    .image-small {
      width: 200px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  h4 {
    font-family: 'Dala Moa', sans-serif;
    font-size: 35px;
    margin-top: 2rem;
  }
  p{
    font-family: 'Gill Sans', sans-serif;
    font-size: 17px;
  }
}