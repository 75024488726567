@font-face {
  font-family: 'spglobal';
  src:  url('fonts/spglobal.eot?mi9vt8');
  src:  url('fonts/spglobal.eot?mi9vt8#iefix') format('embedded-opentype'),
    url('fonts/spglobal.ttf?mi9vt8') format('truetype'),
    url('fonts/spglobal.woff?mi9vt8') format('woff'),
    url('fonts/spglobal.svg?mi9vt8#spglobal') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="sp-"], [class*=" sp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'spglobal' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-strong-chevron-down:before {
  content: "\e909";
}
.sp-strong-chevron-left:before {
  content: "\e90a";
}
.sp-strong-chevron-right:before {
  content: "\e90b";
}
.sp-strong-chevron-up:before {
  content: "\e90c";
}
.sp-chevron-left:before {
  content: "\e907";
}
.sp-chevron-right:before {
  content: "\e908";
}
.sp-arrow-right:before {
  content: "\e906";
}
.sp-chevron-down:before {
  content: "\e904";
}
.sp-chevron-up:before {
  content: "\e905";
}
.sp-minus:before {
  content: "\e900";
}
.sp-person:before {
  content: "\e901";
}
.sp-plus:before {
  content: "\e902";
}
.sp-search:before {
  content: "\e903";
}
