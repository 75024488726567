// Override default variables before the import
$body-color: #111;
$primary:  #046E71;
$dark:  #000;
//
body {
  margin: 0;
  padding: 0;    
  overflow-x: hidden;
}


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '../fonts/dalamoa/stylesheet.css';
@import '../fonts/eternate/stylesheet.css';
@import '../fonts/gill-sans/stylesheet.css';
@import '../fonts/gramatika/stylesheet.css';
@import '../fonts/spglobal/style.css';

@import "variables";