@include media-breakpoint-up(xl) {
  .hero {
    > .container {
      width: 100%;
      max-width: 100%;

      > .row {
        > [class*="col"] {
          &:last-child {
            padding-left: 5rem;
          }
        }
      }
    }
  }

  .hero-bg {
    width: $hero-bg-width-xl;
  }

  .hero-video-preview {
    z-index: 3;
  }
}