@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "assets/styles/variables";

h4.text-primary {
  font-size: $font-size-base;
  font-family: 'Gill Sans', sans-serif;
  font-weight: 600;
}

h5.text-primary, h6.description {
  font-size: $font-size-sm;
  font-family: 'Gill Sans', sans-serif;
  margin: .25rem 0 .45rem 0;
}

h5.text-primary {
  font-weight: 600;
}

h6.description {
  font-weight: normal;
}

h6.description--light {
  color: gray;
  font-weight: lighter;
}

img.images {
  max-width: 40%;
}

p.small {
  font-family: 'Gill Sans', sans-serif;
  font-style: italic;
  font-weight: 300;
}