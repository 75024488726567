@include media-breakpoint-down(sm) {
  .hero {
    padding-top: $header-height;
    height: 100vh;
    min-height: 500px;
    margin-top: 0;

    > .container {
      height: 100%;
      width: 100%;
      max-width: none;
      padding: 0;

      > .row {
        height: 100%;
        width: 100%;
        max-width: none;
        margin: 0;

        > div[class*="col"] {
          padding: 0;
          position: initial;
        }
      }
    }
  }

  .hero-bg {
    background: none !important;
  }

  .hero-text {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .hero-text__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 3rem;

    h3 {
      color: #ffffff;
      font-size: 35px;
    }
  }

  .hero-text__paragraph {
    display: none;
    ////position: absolute;
    top: 100%;
  }

  .hero-slider {
    position: absolute;
    bottom: 0;
  }


  .hero-video-preview {
    position: absolute;
    top: 114px;
    right: 0;
    z-index: 999;
  }
}